import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('deviceAccounts', ['addAccount']),
        redirect(redirect_url) {
            const webview = this.$route.query.webview;

            if (webview && webview === 'envia') {
                const formatted_url = Buffer.from(redirect_url).toString('base64');
                window.location.replace(`enviaclientapp://webView?path=${formatted_url}`);
                return;
            }

            window.location.replace(redirect_url);
        },
        async socialNetWorkSucess(userData) {
            try {
                let phone_url = `/phone-verification?social=true`;
                let documents_url = `/documents?from=login`;
                const payload = { _tk: userData._tk, uri: window.location.href };

                if (this.user.siteId) {
                    payload.site_id = this.user.siteId;

                    const siteIdParam = `&site_id=${this.user.siteId}`;
                    phone_url += siteIdParam;
                    documents_url += siteIdParam;
                }

                if (this.user.redirect_url) {
                    const redirectUrlParam = `&redirect_url=${this.user.redirectUrl}`;
                    phone_url += redirectUrlParam;
                    documents_url += redirectUrlParam;
                }

                const config = { headers: { 'Authorization': `${userData._tk}` } };
                const { data } = await this.axiosAccount.post('/api/login', { token: '' }, config);
                const { email, redirect_url, redirect } = data;
                const emailParam = `&email=${email}`;

                phone_url += emailParam;
                documents_url += emailParam;

                if (email) {
                    this.addAccount({ email })
                }

                if (redirect === 'register-phone') {
                    this.$refs.formRegisterPhone.showModal = true;
                    this.$refs.formRegisterPhone.step = 0;
                } else if (redirect === 'accept-tcpp') {
                    this.$refs.formTCPP.open = true;
                    this.$refs.formTCPP.step = 3;
                } else if (redirect === 'documents-phone') {
                    this.$router.push(`${documents_url}&next=${Buffer.from(phone_url).toString('base64')}`);
                } else if (redirect === 'phone') {
                    this.$router.push(phone_url);
                } else if (redirect === 'documents') {
                    this.$router.push(documents_url);
                } else {
                    this.redirect(redirect_url);
                }
            } catch (error) {
                this.showError(error);
            }
        },
    }
};