<template>
    <div class="hero">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-lg-6 order-1 order-md-0">
                    <div class="hero__content">
                        <div>
                            <h1 class="hero__title">
                                {{ $t('A secure, smart and easy to use account') }}
                            </h1>
                            <p class="hero__text">
                                {{ $t('Improve your productivity and more easily manage your applications that are integrated into your same account.') }}
                            </p>
                            <div class="hero__social-buttons">
                                <google-login-button text="signin_with" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-lg-6 order-0 order-md-1 d-flex justify-content-center align-items-center">
                    <img src="@/assets/images/home/hero.svg" class="hero__image img-fluid" alt="Tendencys" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GoogleLoginButton from '@/components/ui/GoogleLoginButton.vue';

export default {
    name: 'hero',
    components: { GoogleLoginButton },
};
</script>

<style lang="scss">
.hero {
    background-color: #F5F6F7;
    margin-top: 95px;
    padding-top: 95px;
    padding-bottom: 95px;

    @media(max-width: 768px) {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(max-width: 768px) {
            text-align: center;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 43px;
        color: #202225;

        @media(max-width: 768px) {
            font-size: 36px;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 16px;
        color: #787F84;
    }

    &__social-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: flex-start;

        @media(max-width: 768px) {
            justify-content: center;
        }
    }

    &__image{
        @media(max-width: 768px) {
            height: 300px !important;
            width: auto;
            margin-bottom: 15px;
        }
    }
}
</style>
