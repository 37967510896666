<template>
    <div>
        <div ref="signinBtn"></div>
        <div id="google-prompt-parent"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import socialLoginMixin from '@/plugins/mixin/social-login.mixin';

export default {
    name: 'GoogleLoginButton',
    mixins: [socialLoginMixin],
    props: {
        text: {
            type: String,
            default: 'continue_with',
            required: false,
        },
        type: {
            type: String,
            default: 'standard',
            required: false,
        }
    },
    data() {
        return {
            googleSignInParams: {
                client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            },
            user: {
                _tk: null,
                name: '',
                siteId: null,
                redirectUrl: null,
            },
            modalData: {
                email: null,
                redirectUrl: null,
            },
        };
    },
    computed: {
        ...mapGetters('ui', ['language']),
        buttonConfig() {
            return {
                locale: this.language,
                logo_alignment: 'center',
                size: 'large',
                text: this.text,
                theme: 'outline',
                type: this.type,
                width: '220px',
            }
        }
    },
    watch: {
        language() {
            this.renderButton();
        },
        type() {
            this.renderButton();
        }
    },
    async mounted() {
        if (this.$route.query.redirect_url) {
            this.user.redirectUrl = this.$route.query.redirect_url;
        }

        if (this.$route.query.site_id) {
            this.user.siteId = this.$route.query.site_id;
        }

        // Init google signin
        window.google.accounts.id.initialize({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            callback: this.handleCredentialResponse,
            prompt_parent_id: 'google-prompt-parent',
        });
        this.renderButton();
        window.google.accounts.id.prompt();
    },
    methods: {
        handleCredentialResponse(response) {
            this.user._tk = response.credential;
            this.user.name = 'google';
            this.socialNetWorkSucess(this.user);
        },
        renderButton() {
            window.google.accounts.id.renderButton(
                this.$refs.signinBtn,
                this.buttonConfig
            );
        }
    }
};
</script>

<style lang="scss">
#google-prompt-parent {
    width: auto;
    height: auto;
    position: fixed;
    top: 90px;
    right: 30px;
    z-index: 1032;
}
</style>
